<template>
  <div class="wrap" style="padding: 10px 0px; background-color: #fff;">
    <img v-if="status=='已还款'" src="@/assets/img/rentPay.png" alt="logo图片" width="96%" style="margin-left:2%;">
    <img v-if="status=='未还款'" src="@/assets/img/rentUnpay.png" alt="logo图片" width="96%" style="margin-left:2%;">
    <img v-if="status=='部分还款'" src="@/assets/img/rentPart.png" alt="logo图片" width="96%" style="margin-left:2%;">    
    <van-form input-align="right" error-message-align="right">
      <van-field readonly label="姓名" :value="list.customername" />
      <van-field readonly label="合同号" :value="list.contract_number" />
      <van-field readonly label="车架号" :value="list.vin" />
      <van-field readonly label="期次" :value="list.period" />
      <van-field readonly label="计划日期" :value="list.plan_day" />
      <van-field readonly label="应还金额" :value="list.current_pay" />
      <van-field readonly label="实还金额" :value="list.fact_pay ||'--'" />
    </van-form>
  </div>
</template>
<script>
  import {rentPlanDetail,getOpenId} from '@/server'
  import { Toast } from 'vant';
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
  data() {
    return {
      status: this.$route.query.status,
      contractNumber: this.$route.query.contractNumber,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      period: this.$route.query.period,
      list: {},
      loading: false,
      finished: false,
    };
  },
  mounted(){
    var that = this;
    sessionStorage.setItem('key', 0);
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    if(that.$route.path == '/rentPlanDetail'){
        window.history.pushState('forward', null, '/rentPlanDetail');
      }
    }, false);
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    //  if(sessionStorage.getItem('key') == 3) {
    //     window.history.pushState('forward', null, '/rentPlanDetail');
    //     window.history.forward(1);
    //     alert("aaa")
    //    }
    // }, false);
  },
  created(){
    this.getDetail()
    //如果有openid
    // if(this.openId){
    //   getOpenId({openid:this.openId}).then(res=>{
    //     if(res.wx_code=='0'){
    //       this.$store.commit('bindStatus', res.bind_status);
    //       localStorage.setItem("bindStatus", res.bind_status);
    //       localStorage.setItem("accessToken", res.access_token);
    //       this.$store.commit('accessToken', res.access_token);
    //       localStorage.setItem("openId", res.openid);
    //       this.$store.commit('openId', res.openid);
    //       this.$store.commit('cardId', res.card_id);
    //       localStorage.setItem("cardId", res.card_id);
    //       if(res.bind_status !=='1'){
    //         this.$router.push({ path: '/login?redirect=/rentPlanPay' });
    //         alert("aaa")
    //       }else{
    //         this.getDetail();
    //       }
    //     }else{
    //         Toast.fail({
    //           message: res.msg,
    //           duration:'3000'
    //         });
    //       }
    //   })
    // } else{
    //   if(!this.code) {
    //     this.$store.commit('bindStatus', '1');
    //     localStorage.setItem("bindStatus", '1');
    //     let uiCode = encodeURIComponent(domainUrl+'/rentPlanPay');
    //     window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
    //   }else{//获取code
    //     getOpenId({percode:this.code}).then(res=>{
    //       if(res.wx_code=='0'){
    //         this.$store.commit('bindStatus', res.bind_status);
    //         localStorage.setItem("bindStatus", res.bind_status);
    //         this.$store.commit('accessToken', res.access_token);
    //         localStorage.setItem("accessToken", res.access_token);
    //         this.$store.commit('openId', res.openid);
    //         localStorage.setItem("openId", res.openid);
    //         this.$store.commit('cardId', res.card_id);
    //         localStorage.setItem("cardId", res.card_id);
    //         if(res.bind_status !=='1'){
    //           this.$router.push({ path: '/login?redirect=/rentPlanPay' });
    //         }else{
    //           this.getDetail();
    //         }
    //       }else{
    //         Toast.fail({
    //           message: res.msg,
    //           duration:'3000'
    //         });
    //       }
    //     });
    //   }
    // }
  },
  methods: {
    getDetail(){
      var i = this.period.length;
      var period = '';
      period = this.period.substr(0,i-1);
      const params = {
        contract_number:this.contractNumber,
        period:period
      }
      rentPlanDetail(params).then(res=>{
        if(res.status=='200'){
          this.list = res.data[0]
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    }
  },
};
</script>
<style>
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
#app{
  background: #F8F8F8 !important;
}
</style>